import { render, staticRenderFns } from "./delivery-area.vue?vue&type=template&id=9d64fb08&scoped=true&"
import script from "./delivery-area.vue?vue&type=script&lang=js&"
export * from "./delivery-area.vue?vue&type=script&lang=js&"
import style0 from "./delivery-area.vue?vue&type=style&index=0&lang=less&"
import style1 from "./delivery-area.vue?vue&type=style&index=1&id=9d64fb08&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9d64fb08",
  null
  
)

export default component.exports