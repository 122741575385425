
export const columns = [
  {
    title: '类型',
    dataIndex: 'type',
    // align: 'center',
    width: 70,
    slots: {
      customRender: 'type'
    },
    slotsType: 'format',
    slotsFunc: (val) => formatShopsType(val)
  },
  {
    title: '网点名称',
    dataIndex: 'name',
    // align: 'center',
    width: "12%",
  },
  {
    title: '完整地址',
    dataIndex: 'address',
    // align: 'center'
  },
  {
    title: '财务账户',
    dataIndex: 'financial_account_name',
    align: 'center',
    // width: 170,
    width: "12%",
  },
  {
    title: '负责人',
    dataIndex: 'principal',
    align: 'center',
    width: 180,
    slots: {
      customRender: 'principal'
    },
  },
  {
    title: '收银系统',
    dataIndex: 'offline_cash_register',
    align: 'center',
    width: 80,
  },
  {
    title: '状态',
    dataIndex: 'status',
    align: 'center',
    width: 70,
    slots: {
      customRender: 'status'
    },
    // slotsType: 'format',
    // slotsFunc: (val) => formatBusinessStatus(val)
  },
  {
    title: '操作',
    dataIndex: 'operation',
    width: 120,
    slots: { customRender: 'operation' },
    align: 'center'
  },
]

export const deliveryAreaColumns = [
  // {
  //   title: '类型',
  //   dataIndex: 'type',
  //   align: 'center',
  //   width: 70,
  //   // slots: {
  //   //   customRender: 'type'
  //   // },
  //   // slotsType: 'format',
  //   // slotsFunc: (val) => formatShopsType(val),
    
  //   customRender: (text, row) => {
  //     return {
  //       children: text,
  //       attrs: {
  //         rowSpan: row.outlets_idRowSpan, //需要合并的数据
  //       },
  //     }
  //   },
  // },
  {
    title: '网点名称',
    dataIndex: 'outlets_name',
    align: 'center',
    customRender: (text, row) => {
      return {
        children: text,
        attrs: {
          rowSpan: row.outlets_idRowSpan, //需要合并的数据
        },
      }
    },
  },
  {
    title: '配送范围',
    dataIndex: 'name',
    align: 'center'
  },
  {
    title: '运费',
    dataIndex: 'freight',
    align: 'center',
    width: 70,
  },
  {
    title: '提前预订',
    dataIndex: 'production_time',
    align: 'center',
    width: 80,
    slots: {
      customRender: 'production_time'
    },
    slotsType: 'format',
    slotsFunc: (val) => {
      return val ? (Math.round(val/60)+"h") : "——"
    },
  },
  {
    title: '操作',
    dataIndex: 'operation',
    align: 'center',
    width: 90,
    slots: {
      customRender: 'operation'
    }
  }]

export const shopsType = {
  1:'门店',
  2:'配送站'
}

export function formatShopsType (val) {
  return shopsType[val]
}

export const businessStatus = {
  1:'关店',
  2:'未发布',
  3:'营业'
}

export function formatBusinessStatus (val) {
  return businessStatus[val]
}
